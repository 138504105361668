.backToTopBtnWrapper {
  position: fixed;
  bottom: 1.25rem;
  right: 18px;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  will-change: opacity;
  z-index: 1000;

  &.scrolled {
    opacity: 1;
  }

  @media (min-width: 1610px) {
    left: 50%;
    margin-left: 656px;
  }

  @media screen and (max-width: 1023px) {
    bottom: 73px;
  }
}

.backToTopBtn {
  background-color: theme('colors.toTopBgColor');
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.25);
  color: white;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: opacity 300ms ease-in-out;

  &:hover {
    opacity: 0.9;
  }
}
